<template>
  <div>
    <email-form
        :email="currentEmail"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import EmailForm                from '../../components/form/email/EmailForm.vue'
import { clone }                from '../../utils/utils'
import store                    from '../../store'
import useAPI                   from '../../utils/useAPI'

export default {
  components: { EmailForm },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentEmail = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(currentEmail, val => {
      console.log(val)
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAutocompleteUsers } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    currentEmail.value = clone(store.getters['email/getEmptyEmail'])
    fetchAutocompleteUsers()

    return {
      // Components

      // Data
      currentEmail,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>