<template>
  <div>

    <b-form-group
        :label-for="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
        :label="displayLabel?capitalize($tc(name)):null"
        class="w-100 mb-0"
    >
      <validation-provider
          v-if="isRequired"
          #default="{ errors }"
          :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
          rules="required"
      >
        <v-select
            v-model="localModel"
            :filterable="false"
            :options="options"
            :multiple="multiple"
            :taggable="taggable"
            :clearable="clearable"
            :label="isForEmail?'_displayWithEmail':'_display'"

            @search="onSearch"
        >
          <template #no-options>
            {{ $t('NoResult') }}
          </template>

<!--          <template #option="option">-->
<!--            <span v-if="'email' in option">{{ option._display }} <{{ option.email }}></span>-->
<!--            <span v-else>{{ option._display }}</span>-->
<!--          </template>-->
        </v-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <v-select
          v-else
          v-model="localModel"
          :filterable="false"
          :options="options"
          :multiple="multiple"
          :taggable="taggable"
          :clearable="clearable"
          :label="isForEmail?'_displayWithEmail':'_display'"

          @search="onSearch"
      >
        <template #no-options>
          {{ $t('NoResult') }}
        </template>

        <template #option="option">
          <span v-if="'email' in option">{{ option._display }} <{{ option.email }}></span>
          <span v-else>{{ option._display }}</span>
        </template>
      </v-select>

    </b-form-group>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import vSelect                  from 'vue-select'
import useAPI                   from '../../../../utils/useAPI'
import store                    from '../../../../store'
import { capitalize }           from '../../../../utils/filter'
import { ValidationProvider }   from 'vee-validate'

export default {
  components: { vSelect, ValidationProvider },
  props: {
    model: {},
    name: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isForEmail: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.model, val => {
      localModel.value = val
    })

    watch(localModel, val => {
      emit('update:model', val)
      emit('input', val)
      emit('updated')
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAutocompleteUsers } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      localModel,

      // Computed

      // Methods
      fetchAutocompleteUsers,
    }
  },
  data () {
    return {
      options: [],
      timeout: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    onSearch (search, loading) {
      if (search.length) {
        loading(true)

        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.search(loading, search, this)
        }, 300)
      }
    },
    search (loading, search, vm) {
      let filters = [
        {
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              field: '_all',
              value: search
            }
          ]
        }
      ]

      if (this.isForEmail == true) {
        filters[0].sectionFilters.push({
          logicOperator: 'AND',
          field: 'email',
          operator: 'not_contains',
          value: 'fakeEmail.fake'
        })
      }

      this.fetchAutocompleteUsers({
        filters: filters,
      }).then(response => {
        vm.options = response.results
        loading(false)
      })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>