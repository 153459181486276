<template>
  <div>
<!--    <select2-->
<!--        :name="name"-->
<!--        :options="autocompleteUserEmails"-->
<!--        :model.sync="localModel"-->
<!--        :taggable="taggable"-->
<!--        :push-tags="pushTags"-->
<!--        :multiple="multiple"-->
<!--        :is-required="isRequired"-->
<!--        :display-label="displayLabel"-->
<!--    >-->
<!--      <template #customList="{option}">-->
<!--        <span v-if="'email' in option">{{ option._display }} <{{ option.email }}></span>-->
<!--        <span v-else>{{ option._display }}</span>-->
<!--      </template>-->
<!--    </select2>-->


    <select-ajax-user
        :model.sync="localModel"
        :multiple="true"
        :is-for-email="true"
        :taggable="true"
        :is-required="isRequired"
        :name="name"
        :display-label="false"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import Select2                  from '../Select2.vue'
import useAPI              from '../../../utils/useAPI'
import { clone, isObject } from '../../../utils/utils'
import SelectAjaxUser      from './selectAjax/SelectAjaxUser.vue'

export default {
  components: { SelectAjaxUser, Select2 },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    pushTags: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteUsers } = useAPI()

    const autocompleteUserEmails = computed(() => {
      // console.log(autocompleteUsers.value.length)
      // return autocompleteUsers.value
      return autocompleteUsers.value.filter(u => u.email != null && !u.email.includes('fakeEmail.fake'))
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, val => {
      val.forEach((emailObject, i) => {
        // console.log(clone(emailObject))

        if (
            isObject(emailObject) &&
            !('email' in emailObject) &&
            emailObject._displayWithEmail != null
        ) {
          localModel.value[i].email = emailObject._displayWithEmail
        }
      })
// console.log(localModel.value)

      emit('update:model', localModel.value)
      emit('input', localModel.value)
    })

    watch(() => props.model, val => {
      if (val != localModel.value) {
        localModel.value = val
      }
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      localModel,

      // Computed
      autocompleteUserEmails,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>