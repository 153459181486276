<template>
  <div>
    <div
        class="email-new"

        @dragover="isOverlay = true"
        @dragleave="isOverlay = false"
    >
      <b-overlay
          :show="isOverlay"
          rounded="lg"
      >
        <template #overlay>
          <vue-file-agent
              class="w-100 h-100"
              ref="vueFileAgent"
              v-model="fileRecords"

          />
        </template>


        <b-skeleton-wrapper :loading="!isReady">
          <template #loading>
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </template>

          <div class="w-100 h-100">
            <div class="d-flex justify-content-between">
              <div class="my-auto mr-2 text-nowrap">
                {{ capitalize($t('emailTo:')) }}
              </div>

              <div class="w-100">
                <select-email
                    name="recipient"
                    :model.sync="toLocal"
                    :taggable="true"
                    :push-tags="true"
                    :multiple="true"
                    :is-required="!isTemplate"
                    :display-label="false"
                />
              </div>

              <div
                  v-if="!isCcInputShowed"
                  class="my-auto mx-1 cursor-pointer"
                  :class="{'text-primary': isCcFieldHover}"

                  @mouseover="isCcFieldHover = true"
                  @mouseleave="isCcFieldHover = false"
                  @click="isCcInputShowed = true; isCcFieldHover = false"
              >
                Cc
              </div>

              <div
                  v-if="!isBccInputShowed"
                  class="my-auto mr-1 cursor-pointer"
                  :class="{'text-primary': isBccFieldHover, 'ml-1': isCcInputShowed}"

                  @mouseover="isBccFieldHover = true"
                  @mouseleave="isBccFieldHover = false"
                  @click="isBccInputShowed = true; isBccFieldHover = false"
              >
                Bcc
              </div>
            </div>

            <!--      Cc-->
            <div
                v-if="isCcInputShowed"
                class="d-flex justify-content-between"
            >
              <div
                  class="my-auto pt-1 mr-2 text-nowrap cursor-pointer"
                  :class="{'text-primary': isCcFieldHover}"

                  @mouseover="isCcFieldHover = true"
                  @mouseleave="isCcFieldHover = false"
                  @click="isCcInputShowed = false; isCcFieldHover = false"
              >
                {{ $t('Cc:') }}
              </div>

              <div class="w-100">
                <select-email
                    name="Cc"
                    :model.sync="ccLocal"
                    :taggable="true"
                    :push-tags="true"
                    :multiple="true"
                    :is-required="true"
                    :display-label="false"
                    class="pt-1"
                />
              </div>
            </div>

            <!--      Bcc-->
            <div
                v-if="isBccInputShowed"
                class="d-flex justify-content-between"
            >
              <div
                  class="my-auto pt-1 mr-2 text-nowrap cursor-pointer"
                  :class="{'text-primary': isBccFieldHover}"

                  @mouseover="isBccFieldHover = true"
                  @mouseleave="isBccFieldHover = false"
                  @click="isBccInputShowed = false; isBccFieldHover = false"
              >
                {{ $t('Bcc:') }}
              </div>

              <div class="w-100">
                <select-email
                    name="Bcc"
                    :model.sync="bccLocal"
                    :taggable="true"
                    :push-tags="true"
                    :multiple="true"
                    :is-required="true"
                    :display-label="false"
                    class="pt-1"
                />
              </div>
            </div>

            <!--      Subject-->
            <div
                class="mb-1 d-flex"
            >
              <input-field
                  name="subject"
                  :display-label="false"
                  rules="required"
                  :model.sync="emailLocal.subject"
                  :with-template="true"
                  template-type="email"
                  class="pt-1 w-100"

                  @templateSelected="selectTemplate"
              />

              <join
                  class="mt-1 ml-1"
                  @click="isOverlay=true"
              />

              <div class="ml-1 pt-1 my-auto">
                <div class="d-flex">
                  <b-form-checkbox
                      v-model="emailLocal.hasLinkTracking"
                      class="custom-control-primary my-auto"
                      switch
                  />
                  <span class="my-auto text-nowrap">{{ capitalize($t('hasLinkTracking')) }}</span>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <document-card
                  v-for="(attachment, i) in emailLocal.attachments"
                  :document="attachment"
                  class="mt-2 mb-1 mr-1"
                  style="width:200px;"

                  @delete="emailLocal.attachments.splice(i, 1)"
              />
            </div>

            <div>
<!--              <c-k-editor-->
<!--                  :text.sync="emailLocal.body.content"-->
<!--              />-->

              <input-textarea
                  :text.sync="emailLocal.body.content"
              />
            </div>

          </div>

        </b-skeleton-wrapper>
      </b-overlay>

      <div
          v-if="isOverlay"
          class="mt-1 text-right"
      >
        <close
            @click="isOverlay = false"
            size="sm"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch, onUpdated } from '@vue/composition-api'
import { capitalize }                      from '../../../utils/filter'
import i18n                                from '../../../libs/i18n'
import api                                 from '../../../libs/axios'
import useAPI                              from '../../../utils/useAPI'
import { clone, isEmptyObject, isObject }  from '../../../utils/utils'
import DocumentCard                        from '../../card/Document.vue'
import SelectEmail                         from '../../input/custom/SelectEmail.vue'
import InputField                          from '../../input/Input.vue'
import store                               from '../../../store'
import Join                                from '../../button/Join.vue'
import Close                               from '../../button/Close.vue'
import InputTextarea                       from '../../input/InputTextarea.vue'

export default {
  components: { InputTextarea, Close, Join, InputField, SelectEmail, DocumentCard },
  props: {
    email: {
      type: Object,
      default: () => {}
    },
    isTemplate: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(clone(props.email))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const toLocal = ref([])
    const ccLocal = ref([])
    const bccLocal = ref([])
    const isCcFieldHover = ref(false)
    const isBccFieldHover = ref(false)
    const isCcInputShowed = ref(false)
    const isBccInputShowed = ref(false)
    const editorOptions = ref({
      modules: {
        // toolbar: '#myToolbar'
        toolbar: [
          [{ 'size': [] }],
          // ['bold', 'italic', 'underline', 'strike'],
          // // [{ 'color': [] }, { 'background': [] }],
          // [{ 'script': 'super' }, { 'script': 'sub' }],
          // [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
          // [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          // [{ 'align': [] }],
          // ['link', 'image', 'video']
        ],
      },
      placeholder: capitalize(i18n.t('message'))
    })
    const isOverlay = ref(false)
    const fileRecords = ref([])
    const uploadHeaders = ref({
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    })
    const apiURL = ref(api.defaults.baseURL + 'documents')
    const vueFileAgent = ref(null)
    const fileId = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteUsers } = useAPI()

    const emailLocal = computed({
      get () {
        return props.email
      },
      set (val) {
        // emit('update:email', val)
      }
    })

    const isReady = computed(() => {
      return emailLocal.value != null || !isEmptyObject(emailLocal.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isReady, val => {
      if (val === true) {
        let tmpTo = []
        emailLocal.value.toRecipients.forEach(r => tmpTo.push(r.emailAddress.address))
        if (tmpTo.join(',') != toLocal.value.join(',')
        ) {
          toLocal.value = tmpTo
        }
      }
    })

    watch(toLocal, val => {
      // console.log(clone(val))
      emailLocal.value.toRecipients = []
      val.forEach(email => {
            emailLocal.value.toRecipients.push(
                {
                  emailAddress: getMailAddressName(isObject(email) ? email.email : email)
                }
            )
          }
      )
      // val.forEach(emailString => emailLocal.value.toRecipients.push(
      //         {
      //           emailAddress: getMailAddressName(emailString)
      //         }
      //     )
      // )

      // console.log(clone(emailLocal.value))
    })
    watch(ccLocal, val => {
      emailLocal.value.ccRecipients = []
      val.forEach(emailString => emailLocal.value.ccRecipients.push(
              {
                emailAddress: getMailAddressName(emailString)
              }
          )
      )
    })
    watch(bccLocal, val => {
      emailLocal.value.bccRecipients = []
      val.forEach(emailString => emailLocal.value.bccRecipients.push(
              {
                emailAddress: getMailAddressName(emailString)
              }
          )
      )
    })

    watch(fileRecords, () => {
      if (fileRecords.value.length && !('id' in fileRecords.value[0])) {
        let base64 = null
        let reader = new FileReader()
        reader.readAsDataURL(fileRecords.value[0].file)
        reader.onload = () => {
          base64 = reader.result
        }

        vueFileAgent.value.upload(
            apiURL.value,
            uploadHeaders.value,
            [fileRecords.value.at(-1)],
            (fileData) => {
              // console.log(fileData.file)
              let formData = new FormData()
              formData.append('type', props.documentType)
              formData.append('file', fileData.file)
              return formData
            }
        )
            .then(response => {
              let singleFile = response[0].data
              singleFile._base64 = base64

              emailLocal.value.attachments.push(response[0].data)

              isOverlay.value = false
              fileRecords.value.forEach(f => vueFileAgent.value.deleteFileRecord(f))
            })
            .catch(e => {
              console.log(e)
              isOverlay.value = false
              fileRecords.value.forEach(f => vueFileAgent.value.deleteFileRecord(f))
            })
      } else {
        // Delete file from edited source on put
        // emit('update:singleFile', null)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getMailAddressName = (emailString) => {
      console.log(clone(emailString))
      // if (isObject(emailString) && !Array.isArray() && ('email' in emailString)) {
      //   return {
      //     name: emailString._display,
      //     address: emailString.email
      //   }
      // } else {
      return {
        name: '',
        address: emailString
      }
      // }

    }

    const selectTemplate = template => {
      store.dispatch('template/getTemplate', template.id)
          .then(response => {
            for (const [key, value] of Object.entries(response.data.template)) {
              emailLocal.value[key] = value

              if (key == 'toRecipients') {
                let tmpTo = []
                value.forEach(r => tmpTo.push(r.emailAddress.address))
                if (tmpTo.join(',') != toLocal.value.join(',')) {
                  toLocal.value = tmpTo
                }
              }
            }

          })
    }

    // ------------------------------------------------
    // Events
    // ------------------------------------------------
    onUpdated(x => {
      // console.log('Updated !', emailLocal.value)

      // toRecipients
      let tmpTo = []
      emailLocal.value.toRecipients.forEach(r => tmpTo.push(r.emailAddress.address))
      if (tmpTo.join(',') != toLocal.value.join(',')) {
        toLocal.value = tmpTo
      }

      // ccRecipients
      let tmpCc = []
      emailLocal.value.ccRecipients.forEach(r => tmpCc.push(r.emailAddress.address))
      if (tmpCc.join(',') != ccLocal.value.join(',')) {
        ccLocal.value = tmpCc
      }

      // bccRecipients
      let tmpBcc = []
      emailLocal.value.bccRecipients.forEach(r => tmpBcc.push(r.emailAddress.address))
      if (tmpBcc.join(',') != bccLocal.value.join(',')) {
        bccLocal.value = tmpCc
      }

    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // toRecipients
    let tmpTo = []
    emailLocal.value.toRecipients.forEach(r => tmpTo.push(r.emailAddress.address))
    if (tmpTo.join(',') != toLocal.value.join(',')) {
      toLocal.value = tmpTo
    }

    return {
      // Components
      capitalize,

      // Data
      toLocal,
      ccLocal,
      bccLocal,
      isCcFieldHover,
      isBccFieldHover,
      isCcInputShowed,
      isBccInputShowed,
      editorOptions,
      isOverlay,
      fileRecords,
      vueFileAgent,
      fileId,

      // Computed
      emailLocal,
      isReady,
      autocompleteUsers,

      // Methods
      selectTemplate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    lang="scss"
>
.email-new {
  .b-overlay {
    div:nth-child(2) {
      width: 100%;
      height: 100%;
    }

    .file-input-wrapper {
      height: 100%;

      div {
        //height: 100%;
        position: relative;

        div {
          top: 33%;
        }


        .file-preview-wrapper {
          //top:33%;
        }
      }
    }
  }
}
</style>